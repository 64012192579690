// https://github.com/xdan/datetimepicker

import "jquery-datetimepicker"

$(document).ready(function () {
  jQuery.datetimepicker.setLocale("fr")

  $("input.date_time_filter").datetimepicker({
    format: "d/m/Y H:i"
  })
})
