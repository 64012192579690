/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

console.log("Hello from Webpacker")

$(function() { console.log("Hello from jQuery") })

// utils for rails
import "jquery-ujs"

// jQuery, popper.js and Bootstrap
import "bootstrap"

// jquery ui
// import 'jquery-ui/ui/widgets/autocomplete'
// import 'jquery-ui/ui/widgets/sortable' // no call
// import 'jquery-ui/ui/widgets/dialog' // no call

// jquery observe
// import 'jquery-observe/jquery-observe'

// jquery jeditable
// https://www.npmjs.com/package/jquery-jeditable
// import 'jquery-jeditable/src/jquery.jeditable' // one single call

// Bootstrap rating
// https://www.npmjs.com/package/bootstrap-rating
// import "bootstrap-rating"

// https://github.com/harvesthq/chosen
// import 'chosen-js'

// https://www.npmjs.com/package/intl-tel-input
// import 'intl-tel-input/build/js/intlTelInput-jquery'

// underscore.js
// https://github.com/jashkenas/underscore
// import _ from "underscore"
// window._ = _

// sweet alert 2
// https://github.com/sweetalert2/sweetalert2
// import Swal from 'sweetalert2'
// window.Swal = Swal

import "src/javascript/components/shared/navbar"
import "src/javascript/components/shared/datepicker"
import "src/javascript/components/shared/datetimepicker"
