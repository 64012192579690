document.addEventListener("DOMContentLoaded", () => {
  // https://stackoverflow.com/a/48953349/4792408
  $(".dropdown-submenu > a").on("click", (e) => {
    var submenu = $(e.target)

    $(".dropdown-submenu .dropdown-menu").removeClass("show")
    submenu.next(".dropdown-menu").addClass("show")

    e.stopPropagation()
  })

  $(".dropdown").on("hidden.bs.dropdown", () => {
    // hide any open menus when parent closes
    $(".dropdown-menu.show").removeClass("show")
  })

  $(".collapse").on("show.bs.collapse", () => {
    $(".collapse.show").each( () => {
      $(this).collapse("hide")
    })
  })

  $(document).click( (e) => {
    if (!$(e.target).is(".c-navbar .collapse")) {
      $(".c-navbar .collapse").collapse("hide")
    }
  })
})
